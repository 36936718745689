import { useParams } from 'react-router'
import { Plus as PlusIcon } from 'lucide-react'
import { useState } from 'react'

import Button from '~/components/ui/Button'
import NewProjectModal from '~/components/Projects/NewProject'

import useProjectsQuery from '~/graphql/hooks/useProjectsQuery'

import ProjectCardSkeleton from '~/components/Projects/ProjectCardSkeleton'
import ProjectCard from '~/components/Projects/ProjectCard'

export default function Projects() {
  const { orgId } = useParams<{ orgId: string }>()

  const { projects, loading: loadingProjects } = useProjectsQuery(parseInt(orgId ?? '0'))

  const [isCreating, setIsCreating] = useState(false)

  return (
    <div className="w-full pb-6">
      <div className="px-6 grid gap-6">
        <section className="h-[72px] flex justify-between items-center border-b border-divider">
          <div>
            <h2 className="text-2xl text-content font-semibold">Projects</h2>
          </div>
          <div className="flex gap-4">
            <NewProjectModal
              orgId={orgId}
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              trigger={
                <Button className="flex gap-2">
                  <PlusIcon size={16} />
                  <span>New Project</span>
                </Button>
              }
            />
          </div>
        </section>

        {!isCreating && projects && projects.length === 0 && (
          <section className="w-full h-[calc(100vh_-_180px)] grid place-content-center">
            <div className="text-center grid gap-2">
              <p>You have no projects yet.</p>
              <NewProjectModal
                orgId={orgId}
                isCreating={isCreating}
                setIsCreating={setIsCreating}
                trigger={
                  <Button className="flex gap-1">
                    <PlusIcon size={16} />
                    <span className="text-sm">Start a new project</span>
                  </Button>
                }
              />
            </div>
          </section>
        )}

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8">
          {loadingProjects
            ? Array.from({ length: 8 }).map((_, index) => (
                <ProjectCardSkeleton key={index} />
              ))
            : projects?.map((proj) => (
                <ProjectCard orgId={orgId!} key={proj.id} project={proj} />
              ))}
          {projects && isCreating && <ProjectCardSkeleton />}
        </section>
      </div>
    </div>
  )
}
