import { useEffect } from 'react'
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useAcceptInviteQuery } from '~/graphql/hooks/useAcceptInviteQuery'
import { useAuth } from '~/hooks/useAuth'

import { XIcon } from 'lucide-react'
import Loading from '~/components/ui/Loading'

export default function AcceptInvite() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const inviteToken = searchParams.get('token') ?? ''

  const { logout } = useAuth()

  const { acceptInvite } = useAcceptInviteQuery(inviteToken)

  /**
   * Goals:
   * Handle the following actions: accept, login, register
   *
   * login:
   * should clear out existin logged in user and then redirect to the login page
   *
   * register:
   * should clear out existin logged in user and then redirect to the register page
   *
   * accept:
   * should see the user
   *
   */
  useEffect(() => {
    if (!acceptInvite?.inviteToken) {
      toast.error(
        <div className="flex items-center gap-4">
          <XIcon size={16} className="text-destructive font-semibold" />
          <p>
            <span className="font-semibold">This invitation is invalid.</span>
            <br />
            Please request a new one.
          </p>
        </div>,
        {
          onAutoClose: () => {
            navigate('/login')
          }
        }
      )
    }
  }, [acceptInvite, navigate])

  if (acceptInvite?.action === 'accept') {
    return <Navigate to={'/org'} />
  }

  if (acceptInvite?.action === 'login') {
    void logout()
    window.localStorage.setItem('invite_token', inviteToken)
    return <Navigate to="/login" />
  }

  if (acceptInvite?.action === 'register') {
    void logout()
    window.localStorage.setItem('invite_token', inviteToken)
    return <Navigate to="/register" />
  }

  return <Loading />
}
