import React from 'react'
import {
  Content,
  Root,
  TooltipProvider,
  Trigger,
  Arrow,
  type TooltipContentProps
} from '@radix-ui/react-tooltip'

import { type TooltipProps } from './types'

import classNames from '~/utilities/classNames'

const Tooltip = React.forwardRef<React.ElementRef<typeof Root>, TooltipProps>(
  (
    {
      className,
      children,
      overlay,
      defaultOpen,
      onOpenChange,
      delayDuration = 500,
      open,
      side,
      sideOffset,
      disable,
      align
    },
    ref
  ) => (
    <TooltipProvider>
      <Root
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        open={open}
        disableHoverableContent={disable}
        delayDuration={delayDuration}
      >
        <Trigger asChild>{children}</Trigger>
        <TooltipContent
          ref={ref}
          className={className}
          side={side}
          sideOffset={sideOffset}
          align={align}
        >
          {overlay}
          <Arrow width={10} height={5} />
        </TooltipContent>
      </Root>
    </TooltipProvider>
  )
)
Tooltip.displayName = 'Tooltip'

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Content
    ref={ref}
    sideOffset={sideOffset}
    className={classNames(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
))

TooltipContent.displayName = 'TooltipContent'

export default Tooltip
