import { gql, QueryHookOptions, useQuery } from '@apollo/client'

const JOB_MANIFEST_QUERY = gql`
  query JobManifestQuery($projectId: Int!, $jobId: Int!) {
    getJobManifest(projectId: $projectId, jobId: $jobId) {
      success
      error
      data {
        originalDocumentId
        revisedDocumentId
        comparisonType
        matches {
          originalPageIndex
          revisedPageIndex
          revisedSheetNumber
          revisedSheetTitle
        }
      }
    }
  }
`
export interface JobManifest {
  originalDocumentId: string
  revisedDocumentId: string
  comparisonType: string
  matches: {
    originalPageIndex: number
    revisedPageIndex: number
    revisedSheetNumber?: string
    revisedSheetTitle?: string
  }[]
}

export interface JobManifestQueryResponse {
  getJobManifest: {
    success: boolean
    error: string | null
    data: JobManifest
  }
}

export const useJobManifestQuery = (
  projectId: number,
  jobId: number,
  options?: QueryHookOptions
) => {
  const { data, loading, error, refetch } = useQuery<JobManifestQueryResponse>(
    JOB_MANIFEST_QUERY,
    {
      variables: {
        projectId,
        jobId
      },
      ...options
    }
  )

  return {
    jobManifest: data?.getJobManifest,
    loading,
    error,
    refetch
  }
}
