import { useEffect, useRef } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { env } from '~/env.ts'
import { Job } from '~/graphql/hooks/useReviewQuery'
import {
  setDefaultViewerSettings,
  addAnnotationTooltips,
  loadTextCompare
} from '~/utilities/webviewer'
import { useUser } from '~/graphql/hooks/useUser'
import { useJobManifestQuery, JobManifest } from '~/graphql/hooks/useJobManifestQuery'
import { headerCustomizations } from '~/utilities/webviewer/customizations'
import { useDevcycle } from '~/hooks/useDevcycle'
import mbueIDB from '~/lib/mbueIDB/db'

const mapManifestToPageLabels = (manifest: JobManifest): string[] => {
  const pageLabels = manifest.matches.map((pageMatch) => {
    return pageMatch.revisedSheetNumber ?? (pageMatch.revisedPageIndex + 1).toString()
  })
  return pageLabels
}

const clearCache = async (): Promise<void> => {
  let db: Awaited<ReturnType<typeof mbueIDB.new>> | null = null
  try {
    db = await mbueIDB.new()
    await Promise.all([db.contentLengthStore.deleteAll(), db.pdfChunkStore.deleteAll()])
  } catch (error) {
    console.error('Failed to clear PDF cache:', error)
    throw error
  } finally {
    db?.close()
  }
}

const getDocument = async (url: string): Promise<string | Blob> => {
  try {
    const s3docResponse = await fetch(url, {
      headers: {
        range: 'bytes=1-200'
      }
    })

    const s3resHeaders: Headers = s3docResponse.headers
    const s3DocLastModified: string | null = s3resHeaders.get('last-modified')

    const urlKey = url.split('?')[0]
    const db = await mbueIDB.new()

    const [contentLengthData, pdfChunkData] = await Promise.all([
      db.contentLengthStore.find(urlKey),
      db.pdfChunkStore.findAllByUrl(urlKey)
    ])

    db.close()

    if (!contentLengthData.data?.contentLength || !pdfChunkData.data?.length) {
      return url
    }

    if (
      new Date(s3DocLastModified ?? Date.now()).getTime() >
      new Date(contentLengthData.data.createdAt).getTime()
    ) {
      await clearCache()
      console.log('SW: Clearing cache due to outdated content length')
      return url
    }

    const { contentLength } = contentLengthData.data
    const totalChunks = pdfChunkData.data.reduce(
      (acc, chunk) => acc + chunk.data.byteLength,
      0
    )

    if (contentLength > totalChunks) {
      console.log('SW: Incomplete cache detected, falling back to URL')
      return url
    }

    const arrayBuffer = pdfChunkData.data.map((chunk) => chunk.data)
    return new Blob(arrayBuffer, { type: 'application/pdf' })
  } catch (error) {
    console.error('Error assembling PDF from chunks:', error)
    return url
  }
}

const WebviewerComponent = ({ review }: { review: Job }) => {
  const {
    flags: { isTextSmartdiffEnabled, isIndexedDBCacheEnabled }
  } = useDevcycle()

  const { user } = useUser()
  const { projectId, reviewId } = useParams<{ projectId: string; reviewId: string }>()
  const [searchParams] = useSearchParams()
  const viewerDivRef = useRef<HTMLDivElement>(null)
  const isViewerInitialized = useRef<boolean>(false)

  const { jobManifest, loading: loadingJobManifest } = useJobManifestQuery(
    parseInt(projectId!),
    parseInt(reviewId!)
  )

  useEffect(() => {
    if (loadingJobManifest) return
    if (!user || !review || !viewerDivRef.current || !jobManifest?.data) return

    let webviewerKey: string = env.VITE_WEBVIEWER_DEMO_TOKEN ?? ''
    if (env.VITE_ENV === 'prod' && !user?.isAdmin) {
      webviewerKey = env.VITE_WEBVIEWER_TOKEN ?? ''
    }

    const documentUri =
      review.jobType === 'drawing' || isTextSmartdiffEnabled
        ? review.diffUri
        : review.originalUri

    let webviewerInstance: WebViewerInstance

    const initWebviewer = async () => {
      if (isViewerInitialized.current) return
      try {
        webviewerInstance = await WebViewer(
          {
            path: '/webviewer-e45dd22',
            licenseKey: webviewerKey,
            preloadWorker: 'pdf',
            fullAPI: true
          },
          viewerDivRef.current!
        )

        const document = isIndexedDBCacheEnabled
          ? await getDocument(documentUri)
          : documentUri

        void webviewerInstance.Core.documentViewer.loadDocument(document, {
          extension: 'pdf'
        })

        setDefaultViewerSettings(webviewerInstance)

        if (!isTextSmartdiffEnabled) {
          if (review.jobType === 'text') {
            void loadTextCompare(webviewerInstance, review.originalUri, review.revisedUri)
          }
        }

        webviewerInstance.Core.documentViewer.addEventListener(
          'documentLoaded',
          () => {
            const pageNumber = Number(searchParams.get('pageNumber')) || 1
            const { documentViewer } = webviewerInstance.Core

            if (jobManifest?.data) {
              const pageLabels = mapManifestToPageLabels(jobManifest?.data)
              webviewerInstance.UI.setPageLabels(pageLabels)
            }
            documentViewer.setCurrentPage(pageNumber, false)
            addAnnotationTooltips(webviewerInstance)
            headerCustomizations(webviewerInstance, jobManifest.data)
            const iframeDoc = webviewerInstance.UI.iframeWindow.document
            const pageNavOverlay: HTMLDivElement | null = iframeDoc.querySelector(
              '[data-element="pageNavOverlay"]'
            )
            if (pageNavOverlay) {
              pageNavOverlay.style.display = 'none'
            }
          },
          { once: true }
        )
      } catch (e) {
        console.warn('There was an error initializing the webviewer.', e)
      }
    }

    // Verifies that the flag has been set
    if (isTextSmartdiffEnabled !== null && isIndexedDBCacheEnabled !== null) {
      if (!isIndexedDBCacheEnabled) void clearCache()
      void initWebviewer()
      isViewerInitialized.current = true
    }
  }, [
    user,
    review,
    searchParams,
    jobManifest,
    loadingJobManifest,
    isTextSmartdiffEnabled,
    isIndexedDBCacheEnabled
  ])

  return <div className="h-full w-full viewer" ref={viewerDivRef} />
}

export default WebviewerComponent
