import { useState, PropsWithChildren, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { ReportChanges } from '~/graphql/hooks/useSmartReportQuery'
import { classNames } from '~/utilities'
import Markdown from 'react-markdown'

import {
  ScanSearchIcon,
  TextCursorIcon,
  DraftingCompassIcon,
  PencilRulerIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from 'lucide-react'
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableHeader
} from '~/components/ui/Table'
import Badge, { BadgeProps } from '~/components/ui/Badge'
import Tooltip from '../ui/Tooltip'

const HeaderCell = ({
  children,
  column,
  notSort
}: PropsWithChildren<{
  column: Column<ReportChanges, unknown>
  notSort?: boolean
}>): JSX.Element => {
  return (
    <button
      className="w-full"
      onClick={
        notSort ? undefined : () => column.toggleSorting(column.getIsSorted() === 'asc')
      }
    >
      <div className="flex items-center gap-1">
        <span>{children}</span>
        {!notSort && (
          <div className="flex flex-col justify-center items-center">
            <ChevronUpIcon
              size={13}
              className={`${column.getIsSorted() === 'asc' ? 'text-black' : 'text-disabled'} translate-y-[2px]`}
            />
            <ChevronDownIcon
              size={13}
              className={`${column.getIsSorted() === 'desc' ? 'text-black' : 'text-disabled'} -translate-y-[2px]`}
            />
          </div>
        )}
      </div>
    </button>
  )
}

const Cell = forwardRef<HTMLDivElement, PropsWithChildren<{ className?: string }>>(
  ({ className, children }, ref) => (
    <div
      ref={ref}
      className={classNames('w-full overflow-hidden text-nowrap', className)}
    >
      {children}
    </div>
  )
)

Cell.displayName = 'Cell'

const getColumns = (
  reportType: 'drawing' | 'text' = 'drawing'
): ColumnDef<ReportChanges>[] => {
  const columns: ColumnDef<ReportChanges>[] = [
    {
      accessorKey: 'id',
      header: ({ column }) => {
        return <HeaderCell column={column}>Id</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return <Cell>{value}</Cell>
      },
      maxSize: reportType === 'drawing' ? 40 : 20
    },
    {
      accessorKey: 'discipline',
      header: ({ column }) => {
        return <HeaderCell column={column}>Discipline</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return <Cell>{value}</Cell>
      },
      maxSize: 100
    },
    {
      accessorKey: 'pageIndex',
      header: ({ column }) => {
        return <HeaderCell column={column}>Page #</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return <Cell className="text-center">{parseInt(value) + 1}</Cell>
      },
      maxSize: 30
    },
    {
      accessorKey: 'sheetNumber',
      header: ({ column }) => {
        return <HeaderCell column={column}>Sheet #</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return <Cell>{value}</Cell>
      },
      maxSize: 100
    },
    {
      accessorKey: 'sheetTitle',
      header: ({ column }) => {
        return <HeaderCell column={column}>Sheet Title</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return (
          <Cell className="max-w-full truncate">
            <span className="capitalize">{value.toLowerCase()}</span>
          </Cell>
        )
      },
      maxSize: 150
    },
    {
      accessorKey: 'pageIndex',
      id: 'preview',
      header: () => <div className="w-full text-center">Preview</div>,
      cell: (info) => {
        const value = info.getValue() as number
        return (
          <Cell className="max-w-full flex justify-center">
            <Link to={`${location.pathname}/view?pageNumber=${value + 1}`}>
              <ScanSearchIcon size={22} className="text-primary" />
            </Link>
          </Cell>
        )
      },
      maxSize: reportType === 'drawing' ? 50 : 25
    },
    {
      accessorKey: 'impact',
      header: ({ column }) => {
        return <HeaderCell column={column}>Impact</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return (
          <Cell className="flex justify-center">
            {' '}
            <Badge
              {...(getImpact(value) as BadgeProps)}
              className="w-20 flex justify-center"
            />
          </Cell>
        )
      },
      maxSize: 80
    },
    {
      accessorKey: 'description',
      header: ({ column }) => {
        return (
          <HeaderCell column={column} notSort>
            Description
          </HeaderCell>
        )
      },
      cell: (info) => {
        const value = info.getValue() as string

        return (
          <Cell className="max-w-full truncate max-h">
            <Tooltip
              overlay={
                <div className="max-w-[500px] max-h-[300px] overflow-y-auto text-wrap">
                  <Markdown>{value}</Markdown>
                </div>
              }
              className="border border-content bg-[#333333] text-white"
              side="bottom"
              align="start"
            >
              <span>{value}</span>
            </Tooltip>
          </Cell>
        )
      },
      maxSize: 250
    },
    {
      accessorKey: 'type',
      header: ({ column }) => {
        return <HeaderCell column={column}>Type</HeaderCell>
      },
      cell: (info) => {
        const value = info.getValue() as string
        return (
          <Cell className="w-full flex items-center gap-1">
            {value === 'graphic' && <DraftingCompassIcon size={14} />}
            {value === 'text' && <TextCursorIcon size={14} />}
            {value === 'both' && <PencilRulerIcon size={14} />}
            <span className="capitalize">{value}</span>
          </Cell>
        )
      },
      maxSize: 80
    }
  ]

  return columns
}

const SmartReporting = ({
  data = [],
  reportType
}: {
  data: ReportChanges[]
  reportType: 'drawing' | 'text'
}) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const columns = getColumns(reportType)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility: {
        impact: false,
        type: false,
        preview: true,
        pageIndex: reportType === 'text',
        discipline: reportType === 'drawing',
        sheetNumber: reportType === 'drawing',
        sheetTitle: reportType === 'drawing'
      }
    }
  })

  if (!data) return null

  return (
    <div className="relative w-full rounded-xl border max-h-[calc(100%_-_153px)] overflow-y-auto">
      <Table className="min-w-full table-auto" asChild>
        <TableHeader className="bg-gray-50 sticky top-0 z-10">
          <TableRow>
            {table.getHeaderGroups()[0].headers.map((header) => (
              <TableHead
                key={header.id}
                style={{ maxWidth: `${header.column.columnDef.maxSize}px` }}
                className="py-2 px-2"
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="bg-white divide-y divide-gray-200">
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} className="hover:bg-gray-50">
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className="py-2 px-2"
                  style={{ maxWidth: `${cell.column.columnDef.maxSize}px` }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default SmartReporting

function getImpact(impact: string): { label: string; variant: string } {
  if (impact === 'high') return { label: 'High', variant: 'error' }
  if (impact === 'medium') return { label: 'Medium', variant: 'warning' }
  return { label: 'Low', variant: 'success' }
}
