interface ContentLength {
  url: string
  contentLength: number
  jobId: number
  createdAt: string
  timestamp: number
}

interface UpsertReturn {
  status: string
  message: string
  data: ContentLength
  error: Error | null
}

interface FindReturn {
  status: string
  message: string
  data: ContentLength | null
  error: Error | null
}

interface DeleteAllReturn extends Omit<FindReturn, 'data'> {
  data: null
}

export default class ContentLengthsStore {
  private db: IDBDatabase
  private storeName = 'contentLengths'
  private storeLabel = 'Content length'

  constructor(db: IDBDatabase) {
    this.db = db
  }

  async upsert({ url, contentLength, jobId }: ContentLength): Promise<UpsertReturn> {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(this.storeName, 'readwrite')
      const store = tx.objectStore(this.storeName)

      const upsertRequest = store.put({
        url,
        contentLength,
        jobId
      })

      upsertRequest.onsuccess = (event: Event) => {
        const data = (event.target as IDBRequest<ContentLength>).result
        resolve({
          status: 'success',
          message: `${this.storeLabel} saved successfully`,
          data,
          error: null
        })
      }

      upsertRequest.onerror = (error) => {
        reject({
          status: 'error',
          message: 'Failed to save content length',
          error
        })
      }
    })
  }

  async find(url: string): Promise<FindReturn> {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(this.storeName, 'readonly')
      const store = tx.objectStore(this.storeName)

      const findRequest = store.get(url)

      findRequest.onsuccess = (event: Event) => {
        const data = (event.target as IDBRequest<ContentLength | undefined>).result

        if (data) {
          resolve({
            status: 'success',
            message: `${this.storeLabel} retrieved successfully`,
            data,
            error: null
          })
        } else {
          resolve({
            status: 'error',
            message: `${this.storeLabel} not found`,
            data: null,
            error: null
          })
        }
      }

      findRequest.onerror = (error) => {
        reject({
          status: 'error',
          message: 'Failed to get content length',
          data: null,
          error
        })
      }
    })
  }

  async findByJobId(jobId: number): Promise<FindReturn> {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(this.storeName, 'readonly')
      const store = tx.objectStore(this.storeName)
      const index = store.index('jobIdIndex')

      const findRequest = index.getAll(jobId)

      findRequest.onsuccess = (event: Event) => {
        const data = (event.target as IDBRequest<ContentLength | undefined>).result

        if (data) {
          resolve({
            status: 'success',
            message: `${this.storeLabel} retrieved successfully`,
            data,
            error: null
          })
        } else {
          resolve({
            status: 'error',
            message: `${this.storeLabel} not found`,
            data: null,
            error: null
          })
        }
      }

      findRequest.onerror = (error) => {
        reject({
          status: 'error',
          message: 'Failed to get content length',
          data: null,
          error
        })
      }
    })
  }

  async deleteAll(): Promise<DeleteAllReturn> {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(this.storeName, 'readwrite')
      const store = tx.objectStore(this.storeName)

      const deleteRequest = store.clear()

      deleteRequest.onsuccess = () => {
        resolve({
          status: 'success',
          message: `${this.storeLabel} deleted successfully`,
          data: null,
          error: null
        })
      }

      deleteRequest.onerror = (error) => {
        reject({
          status: 'error',
          message: `Failed to delete ${this.storeLabel}`,
          data: null,
          error
        })
      }
    })
  }
}
