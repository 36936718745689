import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { JobRowData, JobStatus } from './types'

import Button from '~/components/ui/Button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'components/ui/DropdownMenu'
import { EllipsisIcon } from 'lucide-react'
import WebviewerComponent from '~/components/WebviewerComponent'
import { Job } from '~/graphql/hooks/useReviewQuery'
import RerunJob from './RerunJob'
import useAdmin from '~/graphql/hooks/useAdmin'
import { useUser } from '~/graphql/hooks/useUser'
import { toast } from 'sonner'
import CopyToEval from './CopyToEval'

interface JobsActionDropdownProps {
  job: JobRowData
}

export default function JobsActionDropdown({ job }: JobsActionDropdownProps) {
  const [viewing, setViewing] = useState<boolean>(false)
  const [review, setReview] = useState<JobRowData>(job)
  const status = JobStatus[job.status].toLowerCase()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { assignJobToMe } = useAdmin()
  const { user } = useUser()

  const navigate = useNavigate()

  const convertedReview: Job = {
    id: review.id,
    jobType: review.jobType,
    status: status,
    comparisonId: review.comparisonId,
    diffUri: review.diffUri,
    originalUri: review.originalUri,
    revisedUri: review.revisedUri,
    originalDesign: {
      originalFilename: review.originalDesign.originalFilename
    },
    revisedDesign: {
      originalFilename: review.originalDesign.originalFilename
    },
    quality: 0,
    createdAt: review.createdAt,
    startedAt: review.startedAt
  }

  const handleViewJob = (review: JobRowData) => {
    setReview(review)
    navigate(`/admin/jobs/${job.project.id}/${job.id}`)
    setViewing(true)
    setIsDropdownOpen(false)
  }

  const closeDropdown = () => setIsDropdownOpen(false)

  const handleAssignToMe = (review: JobRowData) => {
    if (user) {
      void assignJobToMe({
        jobId: Number(review.id),
        reviewer: user.email,
        reviewStatus: 2,
        qualityStatus: job.internalQCReview.qualityStatus,
        comment: job.internalQCReview.comment
      })
        .then(() => {
          toast(`You claimed jobId: ${job.id}.`)
        })
        .catch((e) => {
          console.error(`Error claiming jobId ${job.id} :`, e)
          toast(`Error claiming jobId ${job.id}`)
        })
        .finally(() => {
          const event = new CustomEvent('RerunSearch', {})
          window.dispatchEvent(event)
        })
    }
    setIsDropdownOpen(false)
  }

  const unassignJob = (review: JobRowData) => {
    if (user) {
      void assignJobToMe({
        jobId: Number(review.id),
        reviewer: '',
        reviewStatus: 1,
        qualityStatus: job.internalQCReview.qualityStatus,
        comment: job.internalQCReview.comment
      })
        .then(() => {
          toast(`You unassigned jobId: ${job.id}.`)
        })
        .catch((e) => {
          console.error(`Error claiming jobId ${job.id} :`, e)
          toast(`Error claiming jobId ${job.id}`)
        })
        .finally(() => {
          const event = new CustomEvent('RerunSearch', {})
          window.dispatchEvent(event)
        })
    }
  }

  const jobReviewer: string = job.internalQCReview?.reviewer ?? ''

  return (
    <>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            <EllipsisIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Job Actions</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {status === 'complete' && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem onClick={() => handleViewJob(review)}>
                View
              </DropdownMenuCheckboxItem>
            </>
          )}

          <DropdownMenuSeparator />
          <DropdownMenuCheckboxItem onClick={(e) => e.stopPropagation()}>
            <div onClick={(e) => e.stopPropagation()} className="flex-grow">
              <RerunJob
                jobId={review.id}
                isRerunning={false}
                closeDropdown={closeDropdown}
                trigger={
                  <a className="flex-grow nonclickable-element block text-red-500">
                    Rerun
                  </a>
                }
              />
            </div>
          </DropdownMenuCheckboxItem>

          <DropdownMenuLabel>Review Actions</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {jobReviewer === user?.email ? (
            <DropdownMenuCheckboxItem onClick={() => unassignJob(review)}>
              Unassign Me
            </DropdownMenuCheckboxItem>
          ) : (
            <DropdownMenuCheckboxItem onClick={() => handleAssignToMe(review)}>
              Assign to Me
            </DropdownMenuCheckboxItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuCheckboxItem onClick={(e) => e.stopPropagation()}>
            <div onClick={(e) => e.stopPropagation()} className="flex-grow">
              <CopyToEval
                jobId={review.id}
                isCopying={false}
                closeDropdown={closeDropdown}
                trigger={
                  <a className="flex-grow nonclickable-element block">Copy to Eval</a>
                }
              />
            </div>
          </DropdownMenuCheckboxItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Files</DropdownMenuLabel>
          {status === 'complete' && (
            <DropdownMenuCheckboxItem
              onClick={() => window.open(review.diffUri, '_blank')}
            >
              <a className="flex-grow nonclickable-element block">Diff</a>
            </DropdownMenuCheckboxItem>
          )}
          <DropdownMenuCheckboxItem
            onClick={() => window.open(review.originalUri, '_blank')}
          >
            <a className="flex-grow nonclickable-element block">Original</a>
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            onClick={() => window.open(review.revisedUri, '_blank')}
          >
            <a className="flex-grow nonclickable-element block">Revised</a>
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {viewing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 h-full w-full pt-20 overflow-auto">
            <button onClick={() => setViewing(false)}>Close</button>
            <WebviewerComponent review={convertedReview} />
          </div>
        </div>
      )}
    </>
  )
}
