import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface DeleteJobResponse {
  deleteJob: boolean
}

const DELETE_JOB_MUTATION = gql`
  mutation ($id: Int!) {
    deleteJob(id: $id)
  }
`

export const useDeleteJobMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<DeleteJobResponse>(
    DELETE_JOB_MUTATION,
    {
      ...options,
      refetchQueries: ['ProjectQuery']
    }
  )

  const deleteJob = (id: number) =>
    mutation({
      variables: { id }
    })

  return {
    deleteJob,
    data: data?.deleteJob ?? null,
    loading,
    error
  }
}

export default useDeleteJobMutation
