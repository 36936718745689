import { useRef, useEffect, useState } from 'react'
import { useDevCycleClient, useIsDevCycleInitialized } from '@devcycle/react-client-sdk'
import { DVCVariableSet } from '@devcycle/js-client-sdk'
import { useUser } from '~/graphql/hooks/useUser'

interface Flags {
  isSmartReportEnabled: boolean | null
  isTextSmartdiffEnabled: boolean | null
  isIndexedDBCacheEnabled: boolean | null
  isPricingPageEnabled: boolean | null
}

export function useDevcycle() {
  const [flags, setFlags] = useState<Flags>({
    isSmartReportEnabled: null,
    isTextSmartdiffEnabled: null,
    isIndexedDBCacheEnabled: null,
    isPricingPageEnabled: null
  })
  const initializedRef = useRef<boolean>(false)
  const devcycleClient = useDevCycleClient()
  const isDevcycleInitialized = useIsDevCycleInitialized()
  const { user } = useUser()

  useEffect(() => {
    if (user && !initializedRef.current) {
      devcycleClient
        .identifyUser({
          user_id: user.id.toString(),
          email: user.email
        })
        .then((variables) => {
          const mappedFlags = flagsMapper(variables)
          setFlags(mappedFlags)
        })
        .catch((error) => {
          const mappedFlags = flagsMapper({})
          setFlags(mappedFlags)
          console.error(error)
        })
      initializedRef.current = true
    }
  }, [user, devcycleClient, isDevcycleInitialized])

  return {
    devcycleClient,
    isDevcycleInitialized,
    flags
  }
}

const flagsMapper = (variables: DVCVariableSet): Flags => {
  return {
    isSmartReportEnabled: (variables['smart-reporting-enabled']?.value ??
      false) as boolean,
    isTextSmartdiffEnabled: (variables['text-smartdiff-enabled']?.value ??
      false) as boolean,
    isIndexedDBCacheEnabled: (variables['indexed-db-cache-enabled']?.value ??
      false) as boolean,
    isPricingPageEnabled: (variables['pricing-page-enabled']?.value ?? false) as boolean
  }
}
