import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'gql/ApolloProvider.tsx'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { env } from './env'
import App from '~/app/App.tsx'
import '~/index.css'
import { Toaster } from '~/components/ui/Toaster'

// Check if service workers are supported
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js?v=0.1.0', { type: 'module' })
      .then((registration) => {
        console.log('SW: Service Worker registered with scope:', registration.scope)
      })
      .catch((error) => {
        console.log('SW: Service Worker registration failed:', error)
      })
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={env.VITE_GOOGLE_OAUTH_CLIENT_ID}>
      <BrowserRouter>
        <ApolloProvider>
          <App />
          <Toaster />
        </ApolloProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
)
