import { TrashIcon } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '~/components/ui/DropdownMenu'

interface ProjectCardMenuProps {
  onClickDelete: () => void
  children?: React.ReactNode
}

export default function ProjectCardMenu({
  onClickDelete,
  children
}: ProjectCardMenuProps): JSX.Element {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-40" align="start">
        <DropdownMenuItem onClick={(event) => event.stopPropagation()}>
          <button
            className="flex gap-2 items-center w-full text-left"
            onClick={onClickDelete}
          >
            <TrashIcon size={14} />
            <span>Delete Project</span>
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
