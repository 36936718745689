import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface DeleteProjectResponse {
  deleteProject: boolean
}

const DELETE_PROJECT_MUTATION = gql`
  mutation ($id: Int!) {
    deleteProject(id: $id)
  }
`

const useDeleteProjectMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<DeleteProjectResponse>(
    DELETE_PROJECT_MUTATION,
    {
      ...options,
      refetchQueries: ['ProjectsQuery']
    }
  )

  const deleteProject = (id: number) =>
    mutation({
      variables: { id }
    })

  return {
    deleteProject,
    data,
    loading,
    error
  }
}

export default useDeleteProjectMutation
