import { gql, QueryHookOptions, useQuery } from '@apollo/client'

const SMART_REPORT_QUERY = gql`
  query GetSmartReport($jobId: Int!) {
    getSmartReport(jobId: $jobId) {
      data {
        changes {
          id
          description
          discipline
          pageIndex
          sheetNumber
          sheetTitle
          boundingBox {
            xmin
            xmax
            ymin
            ymax
          }
        }
      }
      error
      success
    }
  }
`

export interface ReportChanges {
  id: number
  description: string
  discipline: string
  pageIndex: number
  sheetNumber: string
  sheetTitle: string
}

export interface SmartReportQueryResponse {
  getSmartReport: {
    data: {
      changes: ReportChanges[]
    }
    error: string | null
    success: boolean
  }
}

export const useSmartReportQuery = (id: number, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<SmartReportQueryResponse>(
    SMART_REPORT_QUERY,
    {
      variables: {
        jobId: id
      },
      ...options
    }
  )

  return {
    smartReport: data?.getSmartReport.data?.changes ?? [],
    loading,
    error,
    refetch
  }
}
