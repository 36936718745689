import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import { useMemo } from 'react'
import { sortDateDescendant } from '~/utilities/datetimeFormats'

export interface Job {
  id: number
  name: string | null
  jobType: string
  originalDesign: { originalFilename: string }
  revisedDesign: { originalFilename: string }
  message: string
  downloadUrls: {
    preview: string
    original: string
    revised: string
    thumbnail: string
    diff: string
    annotations: string
    annotationJson: string
    textDiff: string
  }
  createdAt: string
  startedAt: string
  completedAt: string | null
  status: number
  statusDetail?: string
  matchedPages: number
  completedPages: number
  failedPages: number
}

export interface ProjectDetail {
  id: number
  name: string
  createdAt: string
  jobs: Job[]
}

export interface ProjectQueryResponse {
  project: ProjectDetail
}

export interface ProjectParams {
  projectId?: number
  jobId?: number
}

const PROJECT_QUERY = gql`
  query ProjectQuery($projectId: Int, $jobId: Int) {
    project(projectId: $projectId, jobId: $jobId) {
      id
      name
      createdAt
      jobs {
        id
        name
        jobType
        originalDesign {
          originalFilename
        }
        revisedDesign {
          originalFilename
        }
        message
        downloadUrls
        createdAt
        startedAt
        completedAt
        status
        matchedPages
        completedPages
        failedPages
      }
    }
  }
`

export const useProjectQuery = (params: ProjectParams, options?: QueryHookOptions) => {
  const { data, loading, error, refetch, startPolling, stopPolling } =
    useQuery<ProjectQueryResponse>(PROJECT_QUERY, {
      variables: {
        projectId: params.projectId ?? null,
        jobId: params.jobId ?? null
      },
      ...options
    })

  const project = useMemo(() => {
    return data?.project ? mapStatusToStatusDetail(data.project) : undefined
  }, [data?.project])

  return {
    project,
    loading,
    error,
    refetchProject: refetch,
    startPolling,
    stopPolling
  }
}

const mapStatusToStatusDetail = (project: ProjectDetail): ProjectDetail => {
  const statusDictionary: Record<number, string> = {
    0: 'setup',
    1: 'review starting',
    2: 'review completed',
    3: 'review error',
    4: 'setup error',
    5: 'smart matching',
    6: 'smart matching Error',
    7: 'user matching'
  }

  const projectCloned = structuredClone(project)

  const mappedJobs = projectCloned.jobs
    .map((job) => ({
      ...job,
      statusDetail: statusDictionary[job.status]
    }))
    .toSorted((a, b) => sortDateDescendant(a.createdAt, b.createdAt))

  return {
    ...projectCloned,
    jobs: mappedJobs
  }
}
