import { FC, PropsWithChildren, useState } from 'react'
import { Link } from 'react-router-dom'
import { EllipsisIcon, Files as FilesIcon, LoaderCircleIcon } from 'lucide-react'
import moment from 'moment'
import { Project } from '~/graphql/hooks/useProjectsQuery'
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card'
import Tooltip from '../ui/Tooltip'
import Thumbnail from './Thumbnail'
import cn from '~/utilities/classNames'
import ProjectCardMenu from './ProjectCardMenu'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '~/components/ui/AlertDialog'
import { toast } from 'sonner'
import useDeleteProjectMutation from '~/graphql/hooks/useDeleteProjectMutation'

interface ProjectCardProps {
  orgId: string
  project: Project
  className?: string
}

const ProjectCard: FC<ProjectCardProps> = ({ orgId, project, className }) => {
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false)
  const createdAt = moment(project.createdAt).fromNow()

  const { deleteProject, loading: isDeleting } = useDeleteProjectMutation()

  const onDeleteConfirm = async () => {
    const response = await deleteProject(project.id)
    if (response) {
      toast.success(`Project ${project.name} deleted.`)
      setIsDeleteConfirmOpen(false)
    }
  }

  return (
    <>
      <Link to={`/org/${orgId}/project/${project.id}/reviews`}>
        <Card className={cn('relative border-divider flex h-48 p-2', className)}>
          <ProjectCardMenu onClickDelete={() => setIsDeleteConfirmOpen(true)}>
            <button className="absolute top-2 right-2 bg-fundation-emphasis rounded-full p-[2px]">
              <EllipsisIcon size={14} />
            </button>
          </ProjectCardMenu>
          <div className="w-1/2 flex flex-col overflow-auto">
            <CardHeader>
              <CardTitle className="text-lg leading-5">{project.name}</CardTitle>
              <p className="text-disabled text-sm">{createdAt}</p>
            </CardHeader>
            <CardContent className="mt-auto">
              <div className="flex gap-8">
                <div className="flex items-center gap-1">
                  <Tooltip overlay="Reviews Count">
                    <FilesIcon size={16} className="text-disabled" />
                  </Tooltip>
                  <span className="text-disabled text-sm">{project.jobs.length}</span>
                </div>
              </div>
            </CardContent>
          </div>
          <div className="w-1/2">
            <CardContent className="pt-4 h-full">
              <Thumbnail
                src={project.thumbnail}
                alt={project.name}
                placeholderText="No reviews"
              />
            </CardContent>
          </div>
        </Card>
      </Link>
      <ConfirmDeleteDialog
        open={isDeleteConfirmOpen}
        setOpen={setIsDeleteConfirmOpen}
        onConfirm={onDeleteConfirm}
        loading={isDeleting}
        project={project.name}
      />
    </>
  )
}

export default ProjectCard

type ConfirmDialogProps = PropsWithChildren<{
  open?: boolean
  setOpen?: (open: boolean) => void
  onConfirm: () => Promise<void>
  loading?: boolean
  project: string
}>

export function ConfirmDeleteDialog({
  children,
  onConfirm,
  open,
  setOpen,
  loading = false,
  project
}: ConfirmDialogProps) {
  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Deletion</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete the project {project.trim()}? <br />
            this action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setOpen?.(false)}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => void onConfirm()} disabled={loading}>
            {loading && <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />}
            Yes, Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
