import { EllipsisIcon, TrashIcon, PencilIcon } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '~/components/ui/DropdownMenu'

interface ReviewsSettingsButton {
  onClickEdit: () => void
  onClickDelete: () => void
}

export default function ReviewsSettingsMenu({
  onClickEdit,
  onClickDelete
}: ReviewsSettingsButton): JSX.Element {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button type="button" className="bg-fundation-emphasis rounded-full p-1">
          <EllipsisIcon size={16} />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuItem>
          <button
            className="flex gap-2 items-center w-full text-left"
            onClick={onClickEdit}
          >
            <PencilIcon size={14} />
            <span>Rename Review</span>
          </button>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <button
            className="flex gap-2 items-center w-full text-left"
            onClick={onClickDelete}
          >
            <TrashIcon size={14} />
            <span>Delete Review</span>
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
